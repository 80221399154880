<template>
  <div id="projectInfo" v-cloak v-loading.fullscreen.lock="loading">
    <div :class="[is_view != 1 ? 'container' : 'left-con']">
      <div class="header flex-between">
        <h1 class="titH">
          <p v-if="is_view == 1 && id > 0">
            <span>{{ $t("projectInfo.projectDetails") }}</span>
            <span :class="[getStatusClass(form.status), 'tit-status']">{{
              formateStatus(form.status)
            }}</span>
          </p>
          <p
            v-else-if="
              is_view != 1 &&
              allData.permission > 1 &&
              id > 0 &&
              allData.status != 4 &&
              allData.is_delete == 0
            "
          >
            {{ $t("projectInfo.editProject") }}
          </p>
          <p v-else>
            {{ $t("layout.newProject") }}
          </p>
        </h1>
        <template
          v-if="
            is_view == 1 &&
            allData.permission > 1 &&
            id > 0 &&
            allData.status != 4 &&
            allData.is_delete == 0
          "
        >
          <div
            v-if="rightShow('CrmTask@projectInfo')"
            @click="editProject(id)"
            class="edit-btn hand"
          >
            <i class="iconfont icon-bianji yellow-text"></i>
            <span>{{ $t("projectInfo.editProject") }}</span>
          </div>
        </template>
      </div>
      <div class="user-out-box">
        <div class="mt24">
          <template v-if="is_view == 1">
            <template
              v-if="
                Date.parse(form.want_end_time) < new Date().getTime() &&
                allData.status != 4
              "
            >
              <el-tooltip
                effect="dark"
                :content="$t('projectManagement.overdue')"
                placement="top-start"
              >
                <span class="red-text project_name ellipsis">
                  {{ form.project_name }}
                </span>
              </el-tooltip>
            </template>
            <span v-else class="project_name ellipsis">
              {{ form.project_name ? form.project_name : $t("projectInfo.no") }}
            </span>
            <span class="progress-text"> {{ form.project_progress }}</span>
          </template>
          <template v-else>
            <span class="tit">
              <span class="red">*</span>
              {{ $t("projectInfo.projectName") }}：
            </span>
            <el-input
              v-model.trim="form.project_name"
              size="small"
              class="inputW"
            ></el-input>
          </template>
        </div>
        <div class="mt24 flex-a-start">
          <template v-if="is_view == 1">
            <p class="desc-content">
              {{ form.info ? form.info : $t("projectInfo.no") }}
            </p>
          </template>
          <template v-else>
            <span class="tit">
              <span class="red">*</span>
              {{ $t("projectInfo.projectDescription") }}：
            </span>
            <el-input
              v-model.trim="form.info"
              size="small"
              type="textarea"
              class="descInput"
              maxlength="400"
              rows="4"
              show-word-limit
            ></el-input>
          </template>
        </div>

        <div class="flex-a-center mt24">
          <span class="tit">{{ $t("projectInfo.Images") }}：</span>
          <div
            class="imgBox"
            @click="showBig(form.project_photo)"
            v-if="form.project_photo !== ''"
          >
            <img
              :src="'api/Common/viewCrmTaskImg' + '?path=' + form.project_photo"
            />
          </div>
          <i
            class="iconfont icon-qingkong del-img_icon hand mr10"
            v-if="form.project_photo !== '' && is_view != 1"
            @click="delImg"
          ></i>
          <template v-if="is_view != 1 && form.project_photo == ''">
            <el-upload
              class="avatar-uploader"
              :accept="uploadAccept"
              action="/home/CrmTask/picUpload"
              :data="{ photo: form.project_photo }"
              :show-file-list="false"
              :on-success="handleAvatarSuccess"
              :on-error="errorUpload"
              :before-upload="beforeAvatarUpload"
            >
              <i class="el-icon-plus avatar-uploader-icon"></i>
            </el-upload>
          </template>
        </div>

        <div class="flex-a-center mt24">
          <span class="tit lineH">
            <span v-show="is_view != 1" class="red">*</span>
            {{ $t("projectInfo.creator") }}：
          </span>
          <template>
            <img :src="allData.createUserPhoto" class="user-avatar" />
            <span class="user-name">{{
              allData.createUserName
                ? allData.createUserName
                : $t("projectInfo.no")
            }}</span>
          </template>
        </div>
        <div class="flex-a-center mt24">
          <span class="tit"> {{ $t("projectInfo.ResponsiblePerson") }}： </span>
          <template v-if="is_view == 1">
            <div
              v-if="chargeGroup && chargeGroup.length > 0"
              class="user-img_box"
            >
              <span
                v-for="(item, index) in chargeGroup"
                :key="index"
                class="mr10 flex-a-center"
              >
                <img :src="item.photo" class="user-avatar" />
                <span class="user-name">{{ item.name }}</span>
              </span>
            </div>
            <template v-else class="user-name">{{
              $t("projectInfo.no")
            }}</template>
          </template>
          <template v-else>
            <el-cascader
              class="w378"
              size="small"
              v-model="chargeGroup"
              :options="chargeUserGroup"
              :show-all-levels="false"
              :props="{ multiple: true }"
              v-if="is_view != 1"
            ></el-cascader>
          </template>
        </div>
        <div class="flex-a-center mt24">
          <span class="tit"> {{ $t("projectInfo.participant") }}： </span>
          <template v-if="is_view == 1">
            <div v-if="joinGroup && joinGroup.length > 0" class="user-img_box">
              <span
                v-for="(item, index) in joinGroup"
                :key="index"
                class="mr10 flex-a-center"
              >
                <img :src="item.photo" class="user-avatar" />
                <span class="user-name">{{ item.name }}</span>
              </span>
            </div>
            <template v-else class="user-name">{{
              $t("projectInfo.no")
            }}</template>
          </template>
          <template v-else>
            <el-cascader
              class="w378"
              size="small"
              v-model="joinGroup"
              :options="joinUserGroup"
              :show-all-levels="false"
              :props="{ multiple: true }"
              v-if="is_view != 1"
            ></el-cascader>
          </template>
        </div>
        <div class="flex-a-center mt24">
          <span class="tit"> {{ $t("projectManagement.observer") }}： </span>
          <template v-if="is_view == 1">
            <div
              v-if="watchGroup && watchGroup.length > 0"
              class="user-img_box"
            >
              <span
                v-for="(item, index) in watchGroup"
                :key="index"
                class="mr10 flex-a-center"
              >
                <img :src="item.photo" class="user-avatar" />
                <span class="user-name">{{ item.name }}</span>
              </span>
            </div>
            <template v-else class="user-name">{{
              $t("projectInfo.no")
            }}</template>
          </template>
          <template v-else>
            <el-cascader
              class="w378"
              size="small"
              v-model="watchGroup"
              :options="watchUserGroup"
              :show-all-levels="false"
              :props="{ multiple: true }"
              v-if="is_view != 1"
            >
              <template slot-scope="{ node, data }">
                <span
                  :class="[{ 'disable-span': node.isLeaf && data.is_disabled }]"
                >
                  {{ data.label }}
                </span>
              </template>
            </el-cascader>
          </template>
        </div>
        <div class="flex-a-center mt24">
          <span class="tit"> {{ $t("projectInfo.projectTags") }}： </span>
          <template v-if="is_view == 1">
            <template v-if="form.tags && form.tags.length > 0">
              <el-tag
                class="mr10"
                v-for="item in form.tags"
                :key="item.name"
                :color="item.value"
                effect="dark"
              >
                {{ item.tag_name }}
              </el-tag>
            </template>
            <template v-else> {{ $t("projectInfo.creator") }} </template>
          </template>
          <template v-else>
            <div id="only-change-tags">
              <el-cascader
                class="tags-cascader w378"
                v-model="form.tags"
                size="small"
                :key="iskeyShowLabel"
                :options="tagsGroup"
                :show-all-levels="false"
                ref="myCascader"
                :props="{ multiple: true }"
                @expand-change="handleExpandChange"
                @visible-change="handleExpandChange"
                @change="handleChange"
              ></el-cascader>
            </div>
            <el-button
              v-if="rightShow('CrmTask@getProjectLabel')"
              size="small"
              @click="fn"
              class="ml10"
              icon="el-icon-plus"
            >
            </el-button>
          </template>
        </div>

        <div class="flex-a-center mt24">
          <span class="tit">
            {{ $t("projectInfo.invitationAuthority") }}：
          </span>
          <template v-if="is_view == 1">
            <p v-if="form.invite_role == 1">
              {{ $t("projectInfo.adminOnly") }}
            </p>
            <p v-else-if="form.invite_role == 2">
              {{ $t("projectInfo.membersOnly") }}
            </p>
            <p v-else-if="form.invite_role == 3">
              {{ $t("projectInfo.freeJoin") }}
            </p>
          </template>
          <template v-else>
            <el-select
              v-model="form.invite_role"
              class="w378"
              filterable
              :placeholder="$t('projectInfo.selectInviteConditions')"
              size="small"
            >
              <el-option
                :label="$t('projectInfo.adminOnly')"
                value="1"
              ></el-option>
              <el-option
                :label="$t('projectInfo.membersOnly')"
                value="2"
              ></el-option>
              <el-option
                :label="$t('projectInfo.freeJoin')"
                value="3"
              ></el-option>
            </el-select>
          </template>
        </div>

        <div class="flex-a-center mt24">
          <span class="tit">
            {{ $t("projectInfo.completionConditions") }}：
          </span>
          <template v-if="is_view == 1">
            <p v-if="form.finish_condition == 1">
              {{ $t("projectInfo.allTasksCompleted") }}
            </p>
            <p v-else>{{ $t("projectInfo.manual") }}</p>
          </template>
          <template v-else>
            <el-select
              v-model="form.finish_condition"
              class="w378"
              filterable
              :placeholder="$t('projectInfo.selectCompletionConditions')"
              size="small"
            >
              <el-option
                :label="$t('projectInfo.allTasksCompleted')"
                value="1"
              ></el-option>
              <el-option
                :label="$t('projectInfo.manual')"
                value="2"
              ></el-option>
            </el-select>
          </template>
        </div>
        <div class="mt24">
          <span class="tit"> {{ $t("projectInfo.estStartTime") }}： </span>
          <template v-if="is_view == 1">
            {{
              allData.want_start_time
                ? allData.want_start_time
                : $t("projectManagement.unfinished")
            }}
          </template>
          <template v-else>
            <el-date-picker
              v-model="form.want_start_time"
              type="datetime"
              size="small"
              format="yyyy-MM-dd HH:mm"
              value-format="yyyy-MM-dd HH:mm:ss"
              :placeholder="$t('projectInfo.estStartTime')"
              :picker-options="pickerOptions0"
            >
            </el-date-picker>
          </template>
        </div>
        <div class="mt24">
          <span class="tit"> {{ $t("projectInfo.estDeadline") }}： </span>
          <template v-if="is_view == 1">
            {{
              allData.want_end_time
                ? allData.want_end_time
                : $t("projectManagement.unfinished")
            }}
          </template>
          <template v-else>
            <el-date-picker
              v-model="form.want_end_time"
              type="datetime"
              size="small"
              format="yyyy-MM-dd HH:mm"
              value-format="yyyy-MM-dd HH:mm:ss"
              :placeholder="$t('projectInfo.estDeadline')"
              :picker-options="pickerOptions1"
            >
            </el-date-picker>
          </template>
        </div>
      </div>
      <div class="log-container" v-if="logData.length && is_view == 1">
        <div class="flex-between tit-top">
          <h1 class="log-tit">
            {{ $t("projectInfo.taskLog") }}
          </h1>
          <div
            class="more hand"
            @click="showMore = !showMore"
            v-if="logData.length >= 6"
          >
            {{ showMore ? $t("projectManagement.hide") : "more" }}
            <i
              :class="[showMore ? 'el-icon-arrow-right' : 'el-icon-arrow-down']"
            ></i>
          </div>
        </div>
        <div :class="{ 'log-h-container': !showMore }">
          <div
            class="log-item"
            v-for="(logItem, index) in logData"
            :key="index"
          >
            <span class="log-time">{{ logItem.log_time }}</span>
            <p class="log-info">
              {{ logItem.admin_name }} {{ logItem.action_desc }}
            </p>
          </div>
        </div>
      </div>
      <div class="handel-box flex-center" v-if="!is_view">
        <zc-icon-btn
          :content="$t('cancel')"
          btn_type="danger"
          btn_class="search_button mr88"
          icon="icon-guanbi"
          v-if="id == '' || is_view != 1"
          @click="cancelTag"
        ></zc-icon-btn>
        <zc-icon-btn
          :content="$t('save')"
          btn_type="success"
          btn_class="search_button mr88"
          icon="icon-queren"
          v-if="
            (id == '' || is_view != 1) && rightShow('CrmTask@projectInfoPut')
          "
          @click="submit(allData.status, 'save')"
        ></zc-icon-btn>
        <zc-icon-btn
          :content="$t('projectInfo.saveAndStart')"
          btn_type="primary"
          btn_class="search_button"
          icon="icon-tijiao"
          v-if="
            ((is_view != 1 && [1, 2].indexOf(allData.status) >= 0) || !id) &&
            rightShow('CrmTask@projectInfoPut')
          "
          @click="submit(3, 'go')"
        ></zc-icon-btn>
      </div>
    </div>
    <el-dialog
      :title="$t('tips')"
      center
      class="tips-dialog"
      :visible.sync="tipsDialogVisible"
      width="300px"
    >
      <span>{{ $t("projectInfo.noProjectPermission") }}</span>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="tipsConfirm">{{
          $t("projectInfo.returnList")
        }}</el-button>
      </span>
    </el-dialog>
    <el-dialog
      :title="$t('projectInfo.previewImage')"
      :visible.sync="bigImgVisible"
      v-move
      width="500px"
    >
      <div class="big-img">
        <img :src="bigImgUrl" />
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="bigImgVisible = false" size="small">{{
          $t("close")
        }}</el-button>
      </span>
    </el-dialog>
    <zc-crm-cus-window
      :is_open="is_open_window_crm_cus"
      open_path=""
      page="1"
      call_back="getData"
    ></zc-crm-cus-window>
  </div>
</template>

<script>
import "@/plugins/move";
import { toMessage } from "@/utils/message";
import ZcIconBtn from "@/components/ZcIconBtn.vue";
import ZcCrmCusWindow from "@/components/ZcCrmCusWindow";

export default {
  name: "ProjectInfo",
  components: {
    ZcIconBtn,
    ZcCrmCusWindow,
  },
  data() {
    return {
      loading: true,
      id: "",
      is_view: 0,
      status: 0,
      iskeyShowLabel: 0,
      userOptions: [],
      tagOptions: [],
      allData: {},
      is_open_window_crm_cus: false,
      logData: [],
      showMore: false,
      tipsDialogVisible: false,
      form: {
        project_name: "",
        info: "",
        project_photo: "",
        want_start_time: "",
        want_end_time: "",
        create_user_id: "",
        charge_user_ids: "",
        join_user_ids: "",
        watch_user_ids: "",
        project_tags_ids: [],
        finish_condition: "2",
        invite_role: "3",
        tags: [],
      },
      tagsGroup: [
        {
          value: 1,
          label: this.$t("projectInfo.blueTag"),
          children: [],
        },
        {
          value: 2,
          label: this.$t("projectInfo.redTag"),
          children: [],
        },
        {
          value: 3,
          label: this.$t("projectInfo.yellowTag"),
          children: [],
        },
        {
          value: 4,
          label: this.$t("projectInfo.orangeTag"),
          children: [],
        },
        {
          value: 5,
          label: this.$t("projectInfo.greenTag"),
          children: [],
        },
      ],
      allTags: {},
      hasTags: {},
      userGroup: [],
      chargeUserGroup: [],
      joinUserGroup: [],
      watchUserGroup: [],
      chargeGroup: [],
      joinGroup: [],
      watchGroup: [],
      isDisable: false,
      uploadAccept: ".png, .jpg, .jpeg, .gif", //上传限制文件
      size: 4 * 1024 * 1024,
      bigImgVisible: false,
      bigImgUrl: "",
      pickerOptions0: {
        // disabledDate: (time) => {
        // }
      },
      pickerOptions1: {
        // disabledDate: (time) => {
        // }
      },
    };
  },
  updated: function () {
    let that = this;
    let a = that.$refs["myCascader"];
    if (a != null) {
      let c = that.$refs["myCascader"].$el;
      var cl = c.getElementsByClassName("el-cascader__tags");
      if (cl.length > 0) {
        var d = cl[0].getElementsByClassName("el-tag--info");
        for (let i = 0; i < d.length; i++) {
          let m =
            that.returnColor(that.allTags[d[i].textContent]["value"]) +
            " el-tag--info el-tag--small el-tag--light";
          d[i].className = m;
        }
      }
    }
  },
  computed: {
    // 状态的颜色
    getStatusClass() {
      return function (value) {
        let statusObj = {
          1: "gray-text",
          2: "yellow-text",
          3: "blue-text",
          4: "black-text",
        };
        return statusObj[value];
      };
    },
  },
  inject: ["rightShow"],
  methods: {
    formateStatus: function (value) {
      let statusObj = {
        1: "待定",
        2: "未开始",
        3: "进行中",
        4: "已完成",
      };
      return statusObj[value];
    },
    addTab: function (
      option = "Index",
      action = "welCome",
      name = "首页",
      param
    ) {
      let url = "/" + option + "/" + action;
      if (param) {
        for (var i in param) {
          url += "/" + param[i];
        }
      }
      console.log(name);
      this.$router.push(url);
    },
    tipsConfirm: function () {
      this.addTab("CrmTask", "projectManagement");
    },
    // 获取列表
    getInfo: function () {
      this.loading = true;
      this.axios
        .post("/home/crmTask/getProjectInfo", {
          id: this.id,
          sel_admin: this.admin,
          is_view: this.is_view,
        })
        .then((res) => {
          this.loading = false;
          if (res.data.status !== 1) {
            this.$message(toMessage("error", res.data.msg));
            return;
          }

          //没有权限
          if (
            res.data.data.permission &&
            res.data.data.permission < 1 &&
            res.data.data.invite_role != 3
          ) {
            this.tipsDialogVisible = true;
            return;
          }

          if (res.data.data.userGroup) {
            this.userGroup = res.data.data.userGroup;
          }
          if (res.data.data.chargeUserGroup) {
            this.chargeUserGroup = res.data.data.chargeUserGroup;
          }
          if (res.data.data.joinUserGroup) {
            this.joinUserGroup = res.data.data.joinUserGroup;
          }
          if (res.data.data.watchUserGroup) {
            this.watchUserGroup = res.data.data.watchUserGroup;
          }
          // if(res.data.tagsGroup) {
          //     this.addTags(res.data.tagsGroup)
          // }

          this.allData = res.data.data;
          if (res.data.data && this.id) {
            this.form = res.data.data;
            this.form.project_tags_ids = res.data.data.project_tags.split(",");
            this.form.want_start_time = res.data.data.want_start_time;
            this.form.want_end_time = res.data.data.want_end_time;
            this.form.create_user_id = String(res.data.data.create_user_id);
            this.form.finish_condition = String(res.data.data.finish_condition);
            this.form.invite_role = String(res.data.data.invite_role);

            if (res.data.data.chargeGroup) {
              this.chargeGroup = res.data.data.chargeGroup;
            }
            if (res.data.data.joinGroup) {
              this.joinGroup = res.data.data.joinGroup;
            }
            if (res.data.data.watchGroup) {
              this.watchGroup = res.data.data.watchGroup;
            }

            this.logData =
              res.data.data.logData === undefined ? [] : res.data.data.logData;
          }
          if (res.data.data.tags) {
            if (this.is_view == 1) {
              this.form.tags = res.data.data.tags;
            } else {
              //赋值标签
              //this.changeAccTags(res.data.tags)
              this.changeTagSort(res.data.data.tagsGroup, res.data.data.tags);
            }
          } else {
            if (res.data.data.tagsGroup) {
              this.changeTagSort(res.data.data.tagsGroup, res.data.data.tags);
            }
          }
          this.handleChange();
        })
        .catch(() => {});
    },
    cancelTag: function () {
      this.$confirm("确定取消吗", this.$t("tips"), {
        confirmButtonText: this.$t("confirm"),
        cancelButtonText: this.$t("cancel"),
        type: "warning",
      })
        .then(() => {
          this.addTab("CrmTask", "projectManagement");
        })
        .catch(() => {});
    },
    handleChange: function () {
      let that = this;
      setTimeout(function () {
        let a = that.$refs["myCascader"];
        if (a != null) {
          let c = that.$refs["myCascader"].$el;
          var cl = c.getElementsByClassName("el-cascader__tags");
          if (cl.length > 0) {
            var d = cl[0].getElementsByClassName("el-tag--info");
            for (let i = 0; i < d.length; i++) {
              let m =
                that.returnColor(that.allTags[d[i].textContent]["value"]) +
                " el-tag--info el-tag--small el-tag--light";
              d[i].className = m;
            }
          }
        }
      }, 500);
    },
    // 改变标签的形式
    changeAccTags: function (data) {
      this.hasTags = [];
      this.form.tags = [];
      data.forEach((item) => {
        let obj = [];
        if (item["value"] == "#0A84FF") {
          //蓝色
          obj.push(1);
        }
        if (item["value"] == "#FF575C") {
          //红色
          obj.push(2);
        }
        if (item["value"] == "#F7BE16") {
          //黄色
          obj.push(3);
        }
        if (item["value"] == "#FF7315") {
          //橙色
          obj.push(4);
        }
        if (item["value"] == "#0ACE0D") {
          //绿色
          obj.push(5);
        }
        obj.push(item.name);
        this.form.tags.push(obj)[item["name"]] = item;
      });
    },
    // 添加标签
    addTags: function (data) {
      //this.tagsGroup = data
      this.tagsGroup[0]["children"] = [];
      this.tagsGroup[1]["children"] = [];
      this.tagsGroup[2]["children"] = [];
      this.tagsGroup[3]["children"] = [];
      this.tagsGroup[4]["children"] = [];
      this.allTags = {};
      data.forEach((item) => {
        let obj = [];
        obj["label"] = item["name"];
        obj["value"] = item["id"];
        obj["color"] = item["value"];
        if (item["value"] == "#0A84FF") {
          //蓝色
          this.tagsGroup[0]["children"].push(obj);
        }
        if (item["value"] == "#FF575C") {
          //红色
          this.tagsGroup[1]["children"].push(obj);
        }
        if (item["value"] == "#F7BE16") {
          //黄色
          this.tagsGroup[2]["children"].push(obj);
        }
        if (item["value"] == "#FF7315") {
          //橙色
          this.tagsGroup[3]["children"].push(obj);
        }
        if (item["value"] == "#0ACE0D") {
          //绿色
          this.tagsGroup[4]["children"].push(obj);
        }
        this.allTags[item["name"]] = item;
      });
    },
    //重组标签的顺序 data 所有标签 tags选择的标签
    changeTagSort: function (data, tags) {
      this.tagsGroup[0]["children"] = [];
      this.tagsGroup[1]["children"] = [];
      this.tagsGroup[2]["children"] = [];
      this.tagsGroup[3]["children"] = [];
      this.tagsGroup[4]["children"] = [];
      this.allTags = {};
      data.forEach((item) => {
        let obj = [];
        obj["label"] = item["name"];
        obj["value"] = item["id"];
        obj["color"] = item["value"];
        if (item["value"] == "#0A84FF") {
          //蓝色
          this.tagsGroup[0]["children"].push(obj);
        }
        if (item["value"] == "#FF575C") {
          //红色
          this.tagsGroup[1]["children"].push(obj);
        }
        if (item["value"] == "#F7BE16") {
          //黄色
          this.tagsGroup[2]["children"].push(obj);
        }
        if (item["value"] == "#FF7315") {
          //橙色
          this.tagsGroup[3]["children"].push(obj);
        }
        if (item["value"] == "#0ACE0D") {
          //绿色
          this.tagsGroup[4]["children"].push(obj);
        }
        this.allTags[item["name"]] = item;
      });
      if (tags == undefined || tags == "" || tags.length == 0) {
        return;
      }
      let i = 0;
      let keyTags = {};
      this.tagsGroup.forEach((item, index) => {
        item["children"].forEach((it) => {
          let obj = [];
          obj.push(index + 1);
          obj.push(it["value"]);
          obj.push(i);
          obj.push(it);
          i++;
          keyTags[it["value"]] = obj;
        });
      });
      let sortTags = [];
      this.hasTags = [];
      this.form.tags = [];

      tags.forEach((item) => {
        let obj = [];
        obj.push(keyTags[item["name"]][0]);
        obj.push(keyTags[item["name"]][1]);
        sortTags[keyTags[item["name"]][2]] = obj;
        this.hasTags[item["name"]] = item;
      });
      this.form.tags = sortTags.filter(function (s) {
        return s;
      });
    },
    returnColor: function (color) {
      let select = {
        "#0A84FF": "only-el-color-tags-one",
        "#FF575C": "only-el-color-tags-two",
        "#F7BE16": "only-el-color-tags-three",
        "#FF7315": "only-el-color-tags-four",
        "#0ACE0D": "only-el-color-tags-five",
      };
      return select[color];
    },
    handleExpandChange: function () {
      this.$nextTick(function () {
        let cc = this.$refs["myCascader"].popperJS;
        if (cc != null) {
          let c = cc._popper;
          let b = c.getElementsByClassName("el-cascader-panel");
          let d = b[0].childNodes;
          //增加按钮
          if (this.rightShow("CrmTask@getProjectLabel")) {
            let left = d[0];
            if (left != null) {
              // let e = left.getElementsByClassName("el-scrollbar__view");
              // let child = e[0];
              // let childLength = child.getElementsByClassName(
              //   "el-cascader-node"
              // );
              // if (childLength.length <= 5) {
              //   child.innerHTML += `<li role="menuitem" id="cascader-menu-7218-0-3" tabindex="-1" class="el-cascader-node" aria-haspopup="true" aria-owns="cascader-menu-7218-0"><label class="el-checkbox"><span class="el-checkbox__input button-icon-color"><span class="el-icon-plus"></span><input type="checkbox" aria-hidden="false" class="el-checkbox__original" value=""></span></label><span class="el-cascader-node__label"><div type="button" class="button-add-tags" onclick="vue.fn()">{{ $t('projectInfo.newTag') }}</div></span></li>`;
              // }
            }
          }
          //改变颜色
          let f = d[1];
          if (f != null) {
            f.style.minWidth = "220px";
            let e = f.getElementsByClassName("el-cascader-node");
            for (let i = 0; i < e.length; i++) {
              e[i].style.height = "40px";
              let objLeft = e[i].getElementsByTagName("label");
              objLeft[0].style.float = "right";
              objLeft[0].style.marginLeft = "70px";
              objLeft[0].style.bottom = "3px";
              e[i].append(objLeft[0]);

              let obj = e[i].getElementsByTagName("span");
              obj[0].style.backgroundColor = this.allTags[obj[0].innerHTML][
                "value"
              ];
              obj[0].style.color = "#fff";
              obj[0].style.maxWidth = "500px";
              obj[0].style.maxHeight = "28px";
              obj[0].style.textAlign = "center";
              obj[0].style.lineHeight = "28px";
              obj[0].title = e[i].innerHTML;
            }
          }
        }
      });
    },
    // 编辑
    editProject: function (id) {
      this.addTab(
        "CrmTask",
        "projectInfo",
        this.$t("projectManagement.project") + this.$t("edit"),
        { id: id }
      );
    },
    // 获取 callback 的内容
    getData: function (data) {
      if (this.is_view != 0) {
        return;
      }
      if (Object.prototype.hasOwnProperty.call(data, "tagsGroup")) {
        this.iskeyShowLabel++;
        this.handleChange();
        //this.addTags(data.tagsGroup)
        let tags = [];
        if (this.form.tags) {
          this.form.tags.forEach((item) => {
            let obj = {};
            obj["name"] = item[1];
            tags.push(obj);
          });
        }
        this.changeTagSort(data.tagsGroup, tags);
      }
    },
    updateTags() {
      if (this.is_view != 0) {
        return;
      }
      this.loading = true;
      this.axios
        .post("/home/crmTask/getProjectInfo", {
          id: this.id,
          sel_admin: this.admin,
          is_view: this.is_view,
        })
        .then((res) => res.data)
        .then((res) => {
          this.loading = false;
          if (res.status !== 1) {
            this.$message(toMessage("error", res.msg));
            return;
          }
          if (res.data.tags) {
            this.changeTagSort(res.data.tagsGroup, res.data.tags);
          } else {
            if (res.data.tagsGroup) {
              this.changeTagSort(res.data.tagsGroup, res.data.tags);
            }
          }
        });
    },
    // 提交
    submit: function (status, type) {
      // 检测必填项
      if (this.form.project_name == "") {
        this.$message(
          toMessage("error", this.$t("projectInfo.pleaseInputProjectName"))
        );
        return;
      }
      if (this.form.info == "") {
        this.$message(
          toMessage("error", this.$t("projectInfo.pleaseInputProjectInfo"))
        );
        return;
      }

      let chargeUser = this.getUserIdArray(this.chargeGroup);
      let joinUser = this.getUserIdArray(this.joinGroup);
      let watchUser = this.getUserIdArray(this.watchGroup);

      //判断权限
      if (this.allData.permission < 3) {
        let chargeUserArray = this.allData.charge_user_ids
          ? this.allData.charge_user_ids.split(",")
          : [];
        if (
          chargeUserArray.length > 0 &&
          !this.includeArray(chargeUser, chargeUserArray)
        ) {
          this.$message(
            toMessage("error", this.$t("projectInfo.noProjectPermission1"))
          );
          return;
        }
      }
      if (this.allData.permission < 2) {
        let joinUserArray = this.allData.join_user_ids
          ? this.allData.join_user_ids.split(",")
          : [];
        if (
          joinUserArray.length > 0 &&
          !this.includeArray(joinUser, joinUserArray)
        ) {
          this.$message(
            toMessage("error", this.$t("projectInfo.noProjectPermission2"))
          );
          return;
        }
      }
      if (this.allData.permission <= 1 || this.allData.permission == "") {
        let watchUserArray = this.allData.watch_user_ids
          ? this.allData.watch_user_ids.split(",")
          : [];
        if (
          watchUserArray.length > 0 &&
          !this.includeArray(watchUser, watchUserArray)
        ) {
          this.$message(
            toMessage("error", this.$t("projectInfo.noProjectPermission3"))
          );
          return;
        }
      }

      //保存并开始的状态要判断负责人
      if (status == 3 && type == "go") {
        if (chargeUser.length == 0) {
          this.$message(
            toMessage("error", this.$t("projectInfo.noProjectPermission4"))
          );
          return;
        }
      }

      if (this.isTheSame(chargeUser, joinUser)) {
        this.$message(toMessage("error", this.$t("projectInfo.errorText1")));
        return;
      }
      if (this.isTheSame(chargeUser, watchUser)) {
        this.$message(toMessage("error", this.$t("projectInfo.errorText2")));
        return;
      }
      if (this.isTheSame(joinUser, watchUser)) {
        this.$message(toMessage("error", this.$t("projectInfo.errorText3")));
        return;
      }

      if (this.form.want_start_time != "" && this.form.want_end_time != "") {
        if (
          new Date(this.form.want_end_time).getTime() <=
          new Date(this.form.want_start_time).getTime()
        ) {
          this.$message(toMessage("error", this.$t("projectInfo.errorText4")));
          return;
        }
      }

      let tagsArray = [];
      if (this.form.tags) {
        this.form.tags.forEach((item) => {
          if (Object.prototype.hasOwnProperty.call(this.hasTags, item[1])) {
            tagsArray.push(this.hasTags[item[1]]["name"]);
          } else {
            tagsArray.push(item[1]);
          }
        });
      }
      this.loading = false;
      this.axios
        .post("/home/crmTask/projectInfoPut", {
          id: this.id,
          project_name: this.form.project_name,
          info: this.form.info,
          status: status,
          project_photo: this.form.project_photo,
          want_start_time: this.form.want_start_time
            ? this.form.want_start_time
            : "",
          want_end_time: this.form.want_end_time ? this.form.want_end_time : "",
          create_user_id: this.form.create_user_id,
          charge_user_ids: chargeUser.join(","),
          join_user_ids: joinUser.join(","),
          watch_user_ids: watchUser.join(","),
          invite_role: this.form.invite_role,
          finish_condition: this.form.finish_condition,
          project_tags: tagsArray ? tagsArray.join(",") : "",
        })
        .then((res) => {
          this.loading = false;
          if (res.data.status != 1) {
            this.$message(toMessage("error", res.data.msg));
            return;
          }
          this.$message(toMessage());
          this.addTab("CrmTask", "projectManagement");
        })
        .catch(() => {});
    },
    includeArray: function (bigArray, smallArray) {
      return smallArray.every((val) => bigArray.includes(Number(val)));
    },
    handleAvatarSuccess: function (res) {
      if (res.status == 0) {
        this.$message(toMessage("error", res.msg));
        return;
      }

      this.form.project_photo = res.data.path;
      this.$message(toMessage());
    },
    beforeAvatarUpload: function (file) {
      let lastName = "." + file.name.replace(/.+\./, "").toLowerCase();
      if (this.uploadAccept.indexOf(lastName) < 0) {
        this.logMessage("warning", this.$t("projectInfo.warningText"));
        return false;
      }
      const isLt2M = file.size < this.size;
      if (!isLt2M) {
        this.$message.error(this.$t("projectInfo.warningText2"));
        return false;
      }
      return true;
    },
    // 大图预览
    showBig: function (url) {
      this.bigImgVisible = true;
      this.bigImgUrl = "/api/Common/viewCrmTaskImg" + "?path=" + url;
    },
    errorUpload: function (res) {
      this.$message(toMessage(res.status == 0 ? "error" : "success", res.msg));
    },
    // 判断数组是否相同
    isTheSame: function (array1, array2) {
      //临时数组存放
      var tempArray1 = []; //临时数组1
      var tempArray2 = []; //临时数组2

      for (let i = 0; i < array2.length; i++) {
        if (!Object.prototype.hasOwnProperty.call(tempArray1, array1[i])) {
          tempArray1[array2[i]] = true;
        }
      }

      for (let i = 0; i < array1.length; i++) {
        if (tempArray1[array1[i]]) {
          tempArray2.push(array1[i]); //array1 中与array2 相同的元素；
        }
      }

      if (tempArray2.length > 0) {
        return true;
      } else {
        return false;
      }
    },
    getUserIdArray: function (userGroup) {
      let userArray = [];
      if (userGroup && userGroup.length > 0) {
        userGroup.forEach((item) => {
          userArray.push(item[1]);
        });
      }
      return userArray;
    },
    // 删除图片
    delImg: function () {
      this.form.project_photo = "";
    },
    fn: function () {
      this.is_open_window_crm_cus = !this.is_open_window_crm_cus;
      this.iskeyShowLabel++;
      this.handleChange();
    },
  },
  watch: {
    $route: function (to, from) {
      if (to !== from) {
        // 强制刷新页面
        location.reload();
      }
    },
    is_open_window_crm_cus: function () {
      if (this.is_open_window_crm_cus == false) {
        this.updateTags();
      }
    },
  },
  created: function () {
    this.id = Number(this.$route.params.id);
    this.is_view = Number(this.$route.params.is_view) || 0;
    this.getInfo();
  },
};
</script>

<style scoped>
#projectInfo {
  padding: 28px !important;
  color: #1c1c1e;
}
.descInput {
  width: 500px;
}

.container {
  padding-bottom: 80px;
}
.tit {
  font-size: 16px;
  color: #606266;
  display: inline-block;
  width: 125px;
  text-align: right;
}
.left-con {
  padding-bottom: 80px;
}
.left-con .tit {
  width: auto;
}
.titH {
  font-size: 24px;
  color: #000000;
  font-weight: 600;
}

.add-list-box p {
  margin-bottom: 10px;
  display: flex;
  align-items: center;
}

.descBox {
  display: flex;
  color: #606266;
}

.user-box {
  display: flex;
  align-items: center;
}

.desc-item .tit {
  width: 100px;
  text-align: right;
}

.descInput {
  width: 500px;
}

.add-user {
  padding: 5px 10px;
  margin: 0 5px;
}

.mt24 {
  margin-top: 24px;
}
.w378 {
  width: 378px;
}
.user-out-box {
  padding: 0 0px 20px;
  display: flex;
  flex-direction: column;
  flex: 1;
}
.imgBox {
  width: 100px;
  height: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 20px;
}
.imgBox img {
  max-width: 100%;
  max-height: 100%;
}

.num-input >>> .el-input__inner {
  text-align: right;
}

.w220 {
  width: 220px;
}
.user-avatar {
  border-radius: 50%;
  width: 20px;
  height: 20px;
  margin-right: 6px;
}
.only-change-tags {
}
.only-el-color-tags {
  background: red;
  border-color: #e9e9eb;
  border-width: 0;
  box-sizing: border-box;
  cursor: pointer;
  display: inline-block;
  font-size: 12px;
  border-style: solid;
  border-radius: 4px;
  white-space: nowrap;
  margin: 4px;
}
.only-el-color-tags-one {
  background: #0a84ff;
  border-color: #e9e9eb;
  border-width: 0;
  box-sizing: border-box;
  cursor: pointer;
  display: inline-block;
  font-size: 12px;
  border-style: solid;
  border-radius: 4px;
  white-space: nowrap;
  margin: 4px;
}
.only-el-color-tags-two {
  background: #ff575c;
  border-color: #e9e9eb;
  border-width: 0;
  box-sizing: border-box;
  cursor: pointer;
  display: inline-block;
  font-size: 12px;
  border-style: solid;
  border-radius: 4px;
  white-space: nowrap;
  margin: 4px;
}
.only-el-color-tags-three {
  background: #f7be16;
  border-color: #e9e9eb;
  border-width: 0;
  box-sizing: border-box;
  cursor: pointer;
  display: inline-block;
  font-size: 12px;
  border-style: solid;
  border-radius: 4px;
  white-space: nowrap;
  margin: 4px;
}
.only-el-color-tags-four {
  background: #ff7315;
  border-color: #e9e9eb;
  border-width: 0;
  box-sizing: border-box;
  cursor: pointer;
  display: inline-block;
  font-size: 12px;
  border-style: solid;
  border-radius: 4px;
  white-space: nowrap;
  margin: 4px;
}
.only-el-color-tags-five {
  background: #0ace0d;
  border-color: #e9e9eb;
  border-width: 0;
  box-sizing: border-box;
  cursor: pointer;
  display: inline-block;
  font-size: 12px;
  border-style: solid;
  border-radius: 4px;
  white-space: nowrap;
  margin: 4px;
}
.handel-box {
  position: fixed;
  bottom: 0;
  width: 100%;
  height: 80px;
  margin-left: -28px;
  background: #fff;
  box-shadow: 0px -2px 10px 0px rgba(0, 0, 0, 0.1);
  z-index: 9;
}
.handel-box >>> .mr88 {
  margin-right: 88px;
}
.handel-box >>> .search_button {
  padding: 6px 46px;
}
.el-select .el-tag__close.el-icon-close {
  background-color: transparent;
}
.el-tag--small {
  color: #fff;
}
.el-tag--small span {
  max-width: 100px;
  display: inline-block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  height: 17px;
}
.project-tags-select {
  color: #fff !important;
  margin-bottom: 10px;
}
.project-tags-select:last-child {
  margin-bottom: 0px;
}
.tag-select-box {
  width: 200px;
}
/* 项目标签 */
.tag-select-box >>> .el-select__input {
  display: none;
}
/* 标签面板 */
.tags-cascader >>> .el-cascader-menu:nth-child(2) .el-cascader-menu__list {
  display: flex;
  flex-wrap: wrap;
  padding: 5px 10px;
}
.project-tags-select.is-multiple,
.project-tags-select.selected::after {
  color: #ff7315;
}
.inputW {
  width: 234px;
}
.big-img img {
  max-width: 100%;
}
.avatar-uploader-icon {
  font-weight: 600;
}
.del-img_icon {
  color: #ff575c;
  font-size: 16px;
}
.progress-text {
  font-weight: 600;
  color: #ff7315;
  margin-left: 5px;
}
.header {
  width: 600px;
}
.desc-content {
  line-height: 1.5;
  color: #4a4a4a;
  font-size: 14px;
  max-width: 400px;
  word-wrap: break-word;
  word-break: break-all;
}
.lineH {
  line-height: 1.5;
}
.project_name {
  font-size: 16px;
  font-weight: 600;
}
.edit-btn {
  font-size: 16px;
  color: #666666;
}
.edit-btn span {
  font-weight: 600;
}
.edit-btn i {
  color: #ff7315;
}
.tit-status {
  font-size: 20px;
}
.log-container {
  position: relative;
  width: 550px;
  margin-top: 78px;
}
.tit-top {
  margin-bottom: 16px;
}
.log-tit {
  font-size: 16px;
  font-weight: 600;
  color: #000000;
}

.log-item {
  margin-bottom: 16px;
  display: flex;
  color: #1c1c1e;
  line-height: 1.5;
}

.log-time {
  opacity: 0.6;
  margin-right: 12px;
}
.log-info {
  max-width: 350px;
}
.log-h-container {
  height: 200px;
  overflow: hidden;
  position: relative;
}
.log-h-container::after {
  content: "";
  position: absolute;
  bottom: 0;
  width: 550px;
  height: 25px;
  background: linear-gradient(rgba(255, 255, 255, 0.001), white);
  pointer-events: none;
}
.more {
  color: #ff7315;
  font-size: 14px;
}
.disable-span {
  display: inline-block;
  text-decoration: line-through;
  color: #c0c4cc;
}
.user-img_box {
  display: flex;
  flex-wrap: wrap;
  /* width: 400px; */
}
</style>